.BusinessLink {
    color: var(--text-color);
    width: 100%;
}

.Business {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin: 1rem 0 0rem;
    padding: 0.5rem;
    border-radius: 9px;
    gap: 9px;
    width: 100%;
    align-items: center;
    background-color: var(--secondary-color);
}
.infoColumn {
    display: flex;
    flex-direction: column;
    width: 84%;
}

.cityState {
    color: var(--thirdary-color);
    font-size: 0.8rem;
}

.businessLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9rem;
    margin-top: 0;
    margin-bottom: 0.15rem;
    font-weight: normal;
}

.businessLink > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
    background-color: var(--secondary-color);
    padding: 1px 3px;
    min-width: 36px;
    border-radius: 21px;
    font-size: 10px;
    margin-left: 10px;
    color: #666;
}

.distance {
    height: 45px;
    width: 54px;
    display: flex;
    flex-direction: column;
    border-radius: 9px;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-color);
    margin-right: 9px;
    margin-top: 4px;
    color: var(--text-color);
}

.distanceText {
    font-size: 0.45rem;
    text-align: center;
    margin-top: 3px;
}


.businessLink a {
    color: var(--text-color);
    text-decoration: none;
}

.Business .imageContainer {
    height: 54px;
    width: 64px;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background: #fff;
    border-radius: 12px;
}

.imageContainer img {
  width: 100%;
}


.BusinessInformation {
  display: flex;
  justify-content: space-between;
}

.BusinessInformation p {
  font-size: 0.88rem;
  font-weight: 300;
  line-height: 1rem;
  margin: 0;
}

.adminLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-basis: max-content;
    gap: 3px;
    width: 25%;
    border-radius: 6px;
    border: 1px solid var(--text-color);
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    color: var(--text-color);
    flex-basis: auto;
}

.dealLink {
    border: 1px solid var(--branding-purple);
    background-color: var(--branding-purple);
    color: var(--white-text);
}

.BusinessReviews h3 {
  margin: 0;
}

.BusinessAddress {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.BusinessReviews {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
}

.BusinessReviews h3 {
  color: #cca353;
  font-weight: 600;
}
.ratingDealCount {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.dealCount {
    font-size: 0.75rem;
}
.ratingWrap {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 9px;
    margin: 0 0 3px
}

.rating {
  font-size: 0.75rem;
  line-height: 1;
    margin: 0;
    padding: 0;
    color: var(--text-color);
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    min-height: 100vh;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden; /* Add this to hide the overflowing parts of the animated background */
}

.backgroundImage {
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background-image: url('../../assets/login/hollywood.jpeg');
    background-size: cover;
    background-position: center;
    animation: kenBurnsContinuous 60s linear infinite;
    z-index: 0;
}

@keyframes kenBurnsContinuous {
    0% {
        transform: translate(0, 0) scale(1);
    }
    25% {
        transform: translate(-2%, -2%) scale(1.02);
    }
    50% {
        transform: translate(-4%, 0) scale(1.04);
    }
    75% {
        transform: translate(-2%, 2%) scale(1.02);
    }
    100% {
        transform: translate(0, 0) scale(1);
    }
}

.absBackground {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.396);
    top: 0;
    left: 0;
    z-index: 0;
}

.logoContainer {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 32px;
    z-index: 1;
    position: relative;
}

.logo {
    max-width: 300px;
    max-height: 100%;
}

.formContainer {
    width: 100%;
    max-width: 400px;
    position: relative;
    z-index: 1;
}

.formCard {
    border-radius: 8px;
    padding: 24px 32px;
}

.title {
    font-size: 24px;
    color: white;
    text-align: center;
    margin-bottom: 54px;
}

.form {
    height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.inputGroup {
    margin-bottom: 16px;
}

.inputWrapper {
    position: relative;
}

.input {
    width: 100%;
    padding: 8px 12px 8px 40px !important;
    background-color: #5d6d7f !important;
    border: 1px solid #5d6d7f !important;
    border-radius: 9px !important;
    color: #e2e8f0 !important;
    font-size: 16px !important;
    line-height: 1.5 !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

.input::placeholder {
    color: #ced4da !important;
    font-size: 10px;
    transform: translateY(-1px);
}

.input:focus {
    outline: none;
    border-color: #3b82f6;
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    color: #94a3b8;
    left: 12px;
}

.submitButton {
    width: 100%;
    padding: 15px 16px;
    background-color: var(--branding-purple);
    color: white;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
}

.submitButton:hover {
    background-color: #2563eb;
}

.loginLink {
    text-align: center;
    margin-bottom: 24px;
    font-size: 15px;
    text-shadow: 0 0 6px #000;
}

.loginLink span {
    color: #cbd5e1;
}

.loginLink a {
    color: var(--branding-purple-light);
    text-decoration: none;
}

.loginLink a:hover {
    text-decoration: underline;
}

.togglePassword {
    position: absolute;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.errorMessage {
    color: #ff4d4f;
    font-size: 14px;
    margin-bottom: 16px;
    margin-top: 16px;
    text-align: center;
}

.disabledButton {
    opacity: 0.5;
    cursor: not-allowed;
}

.disabledButton:hover {
    background-color: var(--branding-purple);
}

.videoContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
}

.backgroundVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    mix-blend-mode: screen;
    opacity: 0;
    transition: opacity 2.1s ease-in-out;
}

.videoLoaded {
    opacity: 1;
}

/* For mobile optimization */
@media (max-width: 778px) {
    .backgroundVideo {
        width: auto;
        height: 100vh;
    }
}

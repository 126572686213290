
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 99999999;
    opacity: 1;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
}

.overlayClosing {
    opacity: 0;
}

.bottomSheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 33px 24px 24px;
    max-height: 84vh;
    height: auto;
    overflow-y: auto;
    z-index: 1;
    max-width: 640px;
    margin: auto;
    touch-action: none; /* Prevents browser handling of touch events */
    will-change: transform; /* Optimizes for animations */
    transform: translateY(100%);
    transition: transform 0.3s ease-out;
}

.bottomSheetOpen {
    transform: translateY(0);
    transition: transform 0.3s ease-out;
}

.bottomSheetClosing {
    transform: translateY(100%);
    transition: transform 0.3s ease-out !important;
}

.bottomSheet.dark {
    background-color: #1a1a1a;
    color: white;
}
.handleTouchArea {
    height: 50px;
    width: 100%;
    top: 0;
    position: absolute;
    left: 0;
}
.handle {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    touch-action: none;
    user-select: none;
    cursor: grab;
    margin-top: 16px;
}

.handleBar {
    width: 48px;
    height: 4px;
    background-color: #e5e5e5;
    border-radius: 9999px;
    transition: background-color 0.2s ease;
}

.handle:active .handleBar {
    background-color: rgba(0, 0, 0, 0.2);
}

.dark .handleBar {
    background-color: #404040;
}

.logoWrapper {
    max-width: 108px;
    height: auto;
}

.lottieIcon {
    max-height: 310px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lottieSvg {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
}

.lottieSvg > div {
    max-height: 80%;
    max-width: 100%;
}
.backgroundImage {
    background-image: url("../../../assets/login/MoneyWeedLeaf.png");
    height: 250px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.backgroundWeedGuy {
    background-image: url("../../../assets/login/weed-guy-hi.svg");
    height: 175px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.backgroundWeedGuyDone {
    background-image: url("../../../assets/login/weed-guy-01.png");
    height: 175px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: -1em;
}

.title {
    font-size: 21px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 24px;
    color: var(--text-color);
}

.subTitle {
    font-size: 15px;
    font-weight: normal;
    text-align: left;

    color: var(--text-color);
}

.dark .title {
    color: white;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.inputGroup {
    position: relative;
}

.input {
    width: 100%;
    padding: 12px 16px 12px 40px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    background-color: white;
    color: #111827;
    font-size: 16px;
    padding: 0.375rem 1rem 0.375rem 2.5rem !important;
}

.dark .input {
    background-color: #1f2937;
    border-color: #374151;
    color: white;
}

.input::placeholder {
    color: #9ca3af;
}

.icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
}

.togglePassword {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: #9ca3af;
    cursor: pointer;
    padding: 4px;
}

.registerText {
    text-align: center;
    font-size: 14px;
    color: #4b5563;
    margin: 16px 0;
}

.dark .registerText {
    color: #9ca3af;
}

.link {
    color: #2563eb;
    text-decoration: none;
    font-weight: 500;
}

.dark .link {
    color: var(--branding-purple);
}

.submitButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    background-color: var(--branding-purple);
    color: var(--white-text);
    padding: 12px 16px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1.15rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.submitButton:hover {
    background-color: #1d4ed8;
}

.error {
    background-color: #fee2e2;
    border: 1px solid #fecaca;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;
    color: #dc2626;
}

.dark .error {
    background-color: rgba(220, 38, 38, 0.1);
    border-color: rgba(220, 38, 38, 0.2);
    color: #fca5a5;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    position: relative;
    justify-content: center;
}

.backButton {
    position: absolute;
    left: 0;
    background: none;
    border: none;
    padding: 8px;
    color: var(--text-color);
    cursor: pointer;
}

.dark .backButton {
    color: white;
}

.termsText {
    text-align: center;
    font-size: 12px;
    color: var(--text-color);
    margin-top: 16px;
}

.dark .termsText {
    color: #9ca3af;
}

.logo {
    width: 100%;
    height: auto;
}

.logoBackButton {
    padding-left: 0;
}


.btn {
    background-color: var(--branding-purple);
}

.orDivider {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 16px 0;
    color: #888;
}

.orDivider::before,
.orDivider::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #ddd;
}

.orDivider::before {
    margin-right: 10px;
}

.orDivider::after {
    margin-left: 10px;
}

.googleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #444;
    border: 1px solid #ddd;
}

.googleButton:hover {
    background-color: #f8f8f8;
}

.googleIcon {
    height: 24px;
    width: 24px;
    margin-right: 10px;
}



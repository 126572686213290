.profileContainer {
    margin-top: 1rem;
    padding-bottom: 300px;

}

.profileNavHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    z-index: 999999;
    position: absolute;
    top: 0;
    left: 0;
}

.leftSideLinks {
    height: 90%;
    display: flex;
    flex-direction: column;
    width: 54px;
    align-items: center;
    justify-content: center;
    padding: 9px;
}
.rightSideLinks {
    height: 90%;
    display: flex;
    display: none;
    flex-direction: column;
    width: 54px;
    justify-content: center;
    gap: 9px;
    padding: 9px;
}

.profileNavHeader a {
    width: 39px;
    height: 39px;
    background-color: var(--icon-transparent);
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.profileNavHeader a svg {
    font-size: 1.26rem;
    color: var(--text-color);
}

.profileNavHeader .leftSideLinks a svg {
    font-size: 1.75rem;
}

.profileHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 94%;
    flex-wrap: wrap;
    background: transparent;
    margin: auto;
    border-radius: 15px;
    padding-bottom: 0;
    position: relative;
    z-index: 2;
}

.iconLink {
    text-decoration: none;
}

.locationDetails {
    width: 66%;
    max-width: 720px;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 6px;
    margin-top: 9px;
}

.locationDetails a {
    width: 45%;
    height: 39px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
    flex-grow: 1;
    background-color: var(--secondary-color);
    border-radius: 9px;
    text-decoration: none;
    color: var(--text-color);
    font-size: 0.75rem;
    cursor: pointer;
}

.locationDetails a:first-child {
    width: 50%;
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-weight: bold;
}
.locationDetails a:last-child {
    width: 50%;
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-weight: bold;
}

.activeMenuBtn {
    background-color: var(--branding-purple) !important;
    color: var(--white-text) !important;
}

.activeMenuBtnGrn {
    background-color: var(--branding-green) !important;
    color: var(--black-text) !important;
}


.map {
    height: 120px !important;
    position: relative;
    border-radius: 15px;
    width: 100% !important;
}

.ratingWrap {
    width: 120px;
    background-color: var(--secondary-color);
    color: var(--text-color);
    font-size: 0.45rem;
    padding: 0.25rem;
    border-radius:6px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.h1 {
    color: var(--text-color) !important;
    background: none !important;
    background-color: transparent !important;
    font-size: 1rem !important;
    padding: 9px 6px 0 !important;
}

.coverImage {
    width: 100%;
    height: 300px;
}

.logoImage {
    margin-top: -37px;
    width: 75px;
    height: 75px;
    border-radius: 15px;
    z-index: 999;
}

.logoImage img {
    height: 100%;
    width: 100%;
    border-radius: 15px;
}

.businessInfo {
    width: 100% ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

.iconsWrap {
    width: 100%;
    max-width: 720px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.hoursLocationInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 222px;
    align-items: center;
    color: var(--text-color) !important;
    font-size: 0.66rem;
}

.cityInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1px;

}

.cityInfo svg {
    color: var(--text-color);
}

.open {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: var(--settings-icon);
    gap: 1px;
}

.closed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: red;
    gap: 3px;

}

.open svg, .closed svg {
    color: var(--text-color);
}

.squares {
    width: auto;
    padding: 3px 6px;
    background-color: var(--secondary-color);
    border-radius: 9px;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    color: var(--text-color);
}

.dealList {
    padding: 0px;
}

.viewMenuButton {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.viewMenuButton:hover {
    background-color: #45a049;
}

.menuHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 0 6px;
    margin: 12px auto;
    height: 19px;
    width: 93%;
    position: relative;
    z-index: 2;
}

.today {
    font-size: 12px;
}

.menuHeaderButtons {
    width: 150px;
    display: flex;
    flex-direction: row;
    justify-content: end;
}

.plusButton {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--branding-blue);
    padding: 0;
    box-sizing: border-box;
    border-radius: 12px;
    width: 54px;
    height: 33px;
    font-size: 21px;
}

.viewAllButton, .viewDealsButton {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--branding-purple);
    padding: 0;
    box-sizing: border-box;
    border-radius: 12px;
    width: 75px;
    height: 33px;
    font-size: 9px;
    color: var(--white-text);
}

.viewDealsButton {
    background-color: var(--branding-green);
    color: var(--black-text);
}

.AppExtraDivLargeScreens {
    display: none;
}
.results {
    margin-top: 9px;
}
@media screen and (min-width: 778px) {
    .profileContainer {
        display: flex;
        flex-direction: row
    }

    .profileHeader {
        display: flex;
        flex-direction: row;
        height: 40vh;
        min-height: 410px;
        left: 9px;
        margin-top: 80px;
        overflow-y: auto;
        padding-top: 0;
        position: fixed;
        top: 0;
        width: 50%;
        z-index: 9;
    }
    .AppExtraDivLargeScreens {
        width: 50%;
        display: block;
    }

    .results {
        width: 50%;
        margin-top: -15px;
    }
}

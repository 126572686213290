.bottomSheetOverlay {
    position: fixed;
    top: 0;
    bottom: -1px;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 9999999;
    backdrop-filter: blur(5px);
    transition: opacity 0.3s ease;
    -webkit-transform: translateZ(0);
}

.bottomSheetOverlay.closing {
    opacity: 0;
}

.bottomSheetContent {
    background-color: var(--background-color);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    max-height: 93vh;
    position: relative;
    box-shadow: 0 -5px 20px rgba(255, 255, 255, 0.2);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    max-width: 640px;
    box-sizing: border-box;
    position: relative;
}

.bottomSheetContent.open {
    animation: bottomSheetSlideUp 0.3s ease-out;
}

.bottomSheetContent.closing {
    transform: translateY(100%);
}

.bottomSheetCloseButton {
    position: absolute;
    top: 6px;
    right: 6px;
    background: var(--background-color);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    color: var(--text-color);
    opacity: 1;
    transition: opacity 0.2s ease;
    z-index: 1;
    height: 36px;
    width: 36px;
    display: flex;
    box-shadow: 0 0 3px #fff;
    justify-content: center;
    align-items: center;
}

.bottomSheetHeader {
    padding: 20px 20px 0;
    border-bottom: 1px solid var(--border-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.businessLogo {
    max-width: 90px;
    max-height: 90px;
    border-radius: 50%;
    margin-right: 18px;
}

.bottomSheetHeader h1 {
    font-size: 24px;
    margin: 0 0 15px;
    color: var(--text-color);
}

.bottomSheetScrollContent {
    overflow-y: auto;
    max-height: calc(93vh - 70px);
}

.businessContent {
    padding: 0;
}

.tabHeader {
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 20px;
}

.tabList {
    display: flex;
    overflow-x: auto;
    padding: 0 20px;
    gap: 20px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.tabList::-webkit-scrollbar {
    display: none;
}

.tabList button {
    background: none;
    border: none;
    padding: 15px 0;
    color: var(--text-color-secondary);
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
}

.tabActive {
    color: var(--text-color) !important;
    border-bottom: 2px solid var(--branding-purple) !important;
}

.detailsSection {
    padding: 0 20px 20px;
}

.detailsSection h2 {
    font-size: 20px;
    margin: 0 0 20px;
    color: var(--text-color);
}

.detailItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: var(--text-color);
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 15px;
    color: var(--text-color-secondary);
}

.link {
    color: var(--text-color);
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.scheduleWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: start;
    align-items: start;
}

.openLabel, .closedLabel {
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
}

.openLabel {
    background-color: var(--branding-green);
    color: var(--black-text);
}

.closedLabel {
    background-color: var(--error-color);
    color: var(--white-text);
}

/* New Amenities Styles */
.amenitiesSection {
    padding: 20px;
    border-top: 1px solid var(--border-color);
}

.sectionTitle {
    font-size: 20px;
    color: var(--text-color);
    margin: 0 0 20px;
    font-weight: 500;
}

.amenitiesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(111px, 1fr));
    gap: 20px;
}

.amenityItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px 10px;
    border-radius: 8px;
    background-color: var(--background-color);
    border: 1px solid var(--border-color);
    transition: transform 0.2s ease;
}

.amenityItem:hover {
    transform: translateY(-2px);
}

.amenityIcon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    color: var(--text-color-secondary);
}

.amenityIconSvg {
    width: 24px;
    height: 24px;
    margin-right: initial;
    color: var(--text-color-secondary);
}

.ageIcon {
    background-color: var(--text-color-secondary);
    color: var(--background-color);
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 600;
    font-size: 16px;
}

.amenityLabel {
    font-size: 14px;
    color: var(--text-color);
    font-weight: 500;
}

@keyframes bottomSheetSlideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

/* Add these new styles to your existing CSS */

.scheduleWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.currentSchedule {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding: 2px 0;
}

.chevron {
    color: var(--text-color-secondary);
    transition: transform 0.2s ease;
}

.chevron.rotated {
    transform: rotate(180deg);
}

.fullSchedule {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid var(--border-color);
}

.scheduleRow {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    color: var(--text-color);
}

.scheduleDay {
    font-weight: 500;
    min-width: 100px;
}

.scheduleTime {
    color: var(--text-color-secondary);
}

/* Today's schedule row highlight */
.scheduleRow.today {
    color: var(--branding-purple);
}

.scheduleRow.today .scheduleTime {
    color: var(--branding-purple);
}

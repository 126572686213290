.backgroundImage {
    position: fixed;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background-size: cover;
    background-position: center;
    animation: kenBurnsContinuous 60s linear infinite;
    z-index: 0;
}

.absBackground {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: var(--abs-background);
    top: 0;
    left: 0;
    z-index: 1;
}

.videoContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;
}

.backgroundVideo {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    object-fit: cover;
    mix-blend-mode: screen;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.videoLoaded {
    opacity: 1;
}

@keyframes kenBurnsContinuous {
    0% {
        transform: translate(0, 0) scale(1);
    }
    25% {
        transform: translate(-6%, -6%) scale(1.06);
    }
    50% {
        transform: translate(-4%, 0) scale(1.04);
    }
    75% {
        transform: translate(-6%, 6%) scale(1.06);
    }
    100% {
        transform: translate(0, 0) scale(1);
    }
}

@media (max-width: 778px) {
    .backgroundVideo {
        width: auto;
        height: 100vh;
    }
}
